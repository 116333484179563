import types from './types'

export default ({
  /** @type {WebSocket} */
  ws: null,
  send(to, type, msg) {
    this.ws.send(JSON.stringify({...msg,to,type}))
  },
  install (Vue, { store, isProd }) {
    const reinstall = () => {
      if (document.hasFocus()) {
        this.install(Vue, { store, isProd })
      } else {
        setTimeout(reinstall, 5000)
      }
    }

    var url = isProd ? 'wss://ws.fumple.pl/apiproxy' : 'wss://ws.fumple.pl/apiproxydev'
    console.log("Connecting to "+url+"...")
    this.ws = new WebSocket(url)
    store.commit(types.WS_STATE, types.WS_STATES.CONNECTING)
    window.fpl$ws = this.ws
    window.loginCallback = null
    this.ws.addEventListener("close", ()=>{
      this.ws = null;
      window.loginCallback = null;
      if(!accessDenied) {
        window._summonToast("Lost connection to APIProxy", "Retrying automatically in 5 seconds if this window is focused...")
        console.log("=== WebSocket closed ===")
        console.log("Retrying in 5 seconds if document is focused...")
        store.commit(types.WS_STATE, types.WS_STATES.OFFLINE)
        setTimeout(() => {
          reinstall()
        }, 5000)
      }
    })
    var beId;
    var accessDenied = false;
    this.ws.addEventListener("message", e=>{
      const json = JSON.parse(e.data)
      if(json.from == "system") {
        if(json.type == "hello") {
          console.log("[System] Hello!")
          console.log("MOTD: "+json.motd)
          console.log("Waiting for login url...")
          this.send("system", "loginRequest", {appId: process.env.NODE_ENV === 'development' ? 0 : 1})
          store.commit(types.WS_STATE, types.WS_STATES.WAITINGFORLOGINURL)
        } else if(json.type == "loginUrl") {
          console.log("Received login url")
          store.commit(types.SET_LOGIN_URL, json.url)
          store.commit(types.WS_STATE, types.WS_STATES.WAITINGFORLOGIN)
          window.loginCallback = code => {
            store.commit(types.SET_LOGIN_URL, null)
            store.commit(types.WS_STATE, types.WS_STATES.LOGGINGIN)
            window.loginCallback = null
            this.send("system", "loginCode", {code, appId: process.env.NODE_ENV === 'development' ? 0 : 1})
            console.log("Logging in...")
          }
        } else if(json.type == "connecting") {
          this.send("system", "disconnect", {sessionID:json.sessionID})
        } else if(json.type == "error"){
          console.log("Received error! "+json.code+"\n"+json.details)
          window._summonToast("Received error!", json.code+"\n"+json.details)
          if(json.code == "targetnotfound") {
            store.commit(types.WS_STATE, types.WS_STATES.CONNECTINGTARGETFAILED)
            window._summonToast("Failed to connect to Bean Economy", "Retrying automatically in 15 seconds...")
            console.log("Retrying in 15 seconds...")
            setTimeout(() => {
              this.send("system", "connect", {target:"beaneconomy"})
              store.commit(types.WS_STATE, types.WS_STATES.CONNECTINGTARGET)
            }, 15000)
          }
          if(json.code == "invalidlogincode") {
            store.commit(types.WS_STATE, types.WS_STATES.LOGGINGINFAILED)
            setTimeout(() => {
              this.send("system", "loginRequest", {appId: process.env.NODE_ENV === 'development' ? 0 : 1})
              store.commit(types.WS_STATE, types.WS_STATES.WAITINGFORLOGINURL)
            }, 5000)
          }
        } else if(json.type == "connected"){
          console.log("Connected to "+json.user+"\n"+json.sessionID)
          beId = json.sessionID;
          store.commit(types.WS_STATE, types.WS_STATES.READY)
        } else if(json.type == "connectingtimedout"){
          store.commit(types.WS_STATE, types.WS_STATES.CONNECTINGTARGETFAILED)
          console.log("Connection to "+json.user+" - "+json.sessionID+" timed out")
          if(json.user == "beaneconomy") {
            window._summonToast("Connection to Bean Economy timed out", "Retrying automatically in 15 seconds...")
            console.log("Retrying in 15 seconds...")
            setTimeout(() => {
              this.send("system", "connect", {target:"beaneconomy"})
              store.commit(types.WS_STATE, types.WS_STATES.CONNECTINGTARGET)
            }, 15000)
          }
        } else if(json.type == "disconnected"){
          store.commit(types.WS_STATE, types.WS_STATES.CONNECTINGTARGETFAILED)
          window._summonToast("Connection to Bean Economy lost", "Retrying automatically in 15 seconds...")
          console.log("Connection to "+json.user+" - "+json.sessionID+" ended")
          console.log("Retrying in 15 seconds...")
          setTimeout(() => {
            this.send("system", "connect", {target:"beaneconomy"})
            store.commit(types.WS_STATE, types.WS_STATES.CONNECTINGTARGET)
          }, 15000)
        } else if(json.type == "loggedIn"){
          console.log("Logged in as "+json.user.username)
          console.log("Our session ID: ", json.sessionID)
          console.log("Asking to connect to BeanEconomy...")
          this.send("system", "connect", {target:"beaneconomy"})
          store.commit(types.WS_STATE, types.WS_STATES.CONNECTINGTARGET)
          store.commit(types.UPDATE_DATA, {user:json.user})
        }
      } else if (beId != null && json.from == beId) {
        if(json.type == "guilds") {
          let d = {...store.state.guilds}
          if(json.action == 0) d = json.values
          else if(json.action == 1) d = {...d, ...json.values}
          else if(json.action == 2) {
            for(let i of json.keys) {
              delete d[i];
            }
          }
          store.commit(types.UPDATE_DATA, {guilds:d})
          console.log("Received guilds", json.action, json.values)
        }
        else if(json.type == "cooldowns") {
          let d = {...store.state.cooldowns}
          if(json.action == 0) d = Object.fromEntries(Object.entries(json.values).map(e=>[e[0],new Date(e[1])]))
          else if(json.action == 1) {
            for(var k in json.values) {
              if(d[k] != null) {
                if(d[k] < new Date(json.values[k])) d[k] = new Date(json.values[k])
              } else {
                d[k] = new Date(json.values[k])
              }
            }
          }
          store.commit(types.UPDATE_DATA, {cooldowns:d})
          console.log("Received cooldowns", json.action, json.values)
        }
        else if(json.type == "commands") {
          let d = {...store.state.commands}
          if(json.action == 0) d = json.values
          else if(json.action == 1) d = {...d, ...json.values}
          else if(json.action == 2) {
            for(let i of json.keys) {
              delete d[i];
            }
          }
          store.commit(types.UPDATE_DATA, {commands:d})
          console.log("Received commands", json.action, json.values)
        }
        else if(json.type == "roles") {
          let d = {...store.state.roles}
          if(json.action == 0) d = json.values
          else if(json.action == 1) d = {...d, ...json.values}
          else if(json.action == 2) {
            for(let i of json.keys) {
              delete d[i];
            }
          }
          store.commit(types.UPDATE_DATA, {roles:d})
          console.log("Received roles", json.action, json.values)
        }
        else if(json.type == "accessdenied") {
          if(json.area == "web") {
            accessDenied = json.reason
            store.commit(types.WS_STATE, types.WS_STATES.ACCESS_DENIED)
            this.ws.close()
          }
        }
      }
    })
  }
})