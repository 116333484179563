<template>
  <transition name="fade" mode="out-in">
    <div class="block" v-if="wsState?.block"></div>
  </transition>
  <div class="header" :class="wsState?.color">
    <div class="header-inner">
      <span style="float: left; cursor: pointer" class="bi bi-backspace ms-1 me-2" v-if="!chanNull && smallScreen" @click="resetChan"></span>
      <div style="float: left;" v-if="wsState != null && wsState.text != ''">
        <b>Status:</b> <span>{{wsState.text}}</span>
      </div>
      <div v-if="wsReady" style="float: right;">
        <b>{{(user) ? `Welcome ${user.username}#${user.discriminator}!` : ''}}</b> <a href="#" @click="logout">Log out</a>
      </div>
    </div>
  </div>
</template>

<script>
import types from '../store/types'
import wsHandler from '../store/ws'

export default {
  name: 'WSStatus',
  computed: {
    user: function () {
      return this.$store.state.user
    },
    wsState: function () {
      return this.$store.state.wsState
    },
    wsReady: function () {
      return this.$store.state.wsState?.id === types.WS_STATES.READY.id
    },
    smallScreen: function () {
      return window.innerWidth <= 500
    },
    chanNull: function () {
      return this.$store.state.selectedChannel == null
    }
  },
  methods: {
    logout () {
      wsHandler.ws.close()
    },
    resetChan () {
      this.$store.commit(types.SET_CHANNEL, null)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity ease-in-out 0.2s;
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.block {
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(0,0,0,0.2);
  /*transition: background-color ease-in-out 1s;*/
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  width:100%;
  height: 39px;
  text-align: left;

  overflow: auto;

  background: #334;
  color: #ddd;
  padding: 0;
  border-bottom: 2px solid #000;

  transition: ease-in-out 0.2s;
  transition-property: border-bottom-color, color, box-shadow;

  border-bottom-color: var(--color);
  box-shadow: 0 0 30px 0 var(--color);
}

.header-inner {
  margin: 2px 6px;
  font-size: larger;
  vertical-align: middle;
}

.header-inner > .fa {
  margin-right: 5px;
  position: relative;
  top: -7px;
  font-size: 30px;
}

a {
  color: #ccc;
}

.red, .red a {
  color: #e33;
}
.yellow, .yellow a {
  color: yellow;
}
.green, .green a {
  color: #3e3;
}

.header.red {
  --color: #e33
}
.header.yellow {
  --color: yellow;
}
.header.green {
  --color: #3e3;
}

.block.red {
  background-color: rgba(238, 51, 51, 0.2);
}
.block.yellow {
  background-color: rgba(255, 255, 0, 0.2);
}
.block.green {
  background-color: rgba(51, 238, 51, 0.2);
}

@media only screen and (max-width: 500px)  {
  .header-inner {
    font-size: 16px;
  }
}
</style>
