var id = 0;
function wsStateB(text, color) {return wsState(text,color,true)}
function wsStateC(text, color) {return wsState(text,color,false)}
function wsState(text, color, block) {id++;return {id,text,color,block}}

export default {
    WS_STATE: 'mut_wsState',
    WS_STATES: {
      NONE:       wsStateB('Loading...', ''),
      OFFLINE:    wsStateC('Connection lost', 'red'),
      READY:      wsStateC('Connected', 'green'),
      CONNECTING: wsStateC('Connecting to APIProxy', 'yellow'),
      WAITINGFORLOGINURL: wsStateC('Waiting for server', 'yellow'),
      WAITINGFORLOGIN: wsStateC('Ready to login', 'green'),
      LOGGINGIN: wsStateC('Logging in', 'yellow'),
      LOGGINGINFAILED: wsStateC('Logging in failed', 'red'),
      CONNECTINGTARGET: wsStateC('Connecting to Bean Economy', 'yellow'),
      CONNECTINGTARGETFAILED: wsStateC('Failed to connect', 'red'),
      // 8,
      ACCESS_DENIED: wsStateC('Failed to connect', 'red'),
      SWITCHING_MODE: wsStateB('Switching mode', 'yellow')
    },

    SET_LOGIN_URL: 'mut_setLoginUrl',
    UPDATE_DATA: 'mut_updateData'
}