<template>
    <div class="toast-container position-absolute top-0 end-0 p-3" ref="el">
        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" ref="template">
            <div class="toast-header">
                <strong class="me-auto">Placeholder</strong>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body ws-pre">
                Placeholder
                Placeholder
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from 'bootstrap';
window._summonToast = (header, body) => {
    var toastNode = template.cloneNode(true);
    toastNode.firstChild.firstChild.textContent = header
    toastNode.lastChild.textContent = body
    el.appendChild(toastNode);
    new Toast(toastNode, {
        delay: 12500
    }).show();
    toastNode.addEventListener('hidden.bs.toast', () => {
        toastNode.remove();
    })
}

/**
 * @type {Element}
 */
var el,
/**
 * @type {Element}
 */
    template;
export default {
    name: "ToastsContainer",
    mounted() {
        el = this.$refs.el
        template = this.$refs.template
    }
}
</script>

<style>

</style>