import { createStore } from 'vuex'
import types from './types'

export default createStore({
  state: {
    loginUrl: null,
    wsState: types.WS_STATES.NONE,
    guilds: {},
    cooldowns: {},
    commands: {},
    roles: {},
    user: {},
    wsVer: null
  },
  getters: {
  },
  mutations: {
    [types.WS_STATE] (state, payload) {
      state.wsState = payload
    },
    [types.SET_LOGIN_URL] (state, payload) {
      state.loginUrl = payload
    },
    [types.UPDATE_DATA] (state, { guilds, cooldowns, commands, roles, user }) {
      if(guilds != null) state.guilds = guilds
      if(cooldowns != null) state.cooldowns = cooldowns
      if(commands != null) state.commands = commands
      if(roles != null) state.roles = roles
      if(user != null) state.user = user
    },
    [types.SET_WSVER] (state, payload) {
      state.wsVer = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
