<template>
  <div class="m-4 text-white">
    <div class="alert alert-dark legend" role="alert">
      <h4 class="alert-heading">Legend</h4>
      <img src="./assets/online.webp" class="emoji" /> <span class="emoji-text">You can run this command</span><br/>
      <img src="./assets/idle.webp" class="emoji" /> <span class="emoji-text">You can't run this command because of a cooldown</span><br/>
      <img src="./assets/dnd.webp" class="emoji" /> <span class="emoji-text">You can't run this command</span>
    </div>
    <select class="form-select" aria-label="Server selection" v-model="selectedGuild" :disabled="noData">
      <option selected :value="null" v-if="noData">Waiting for server list...</option>
      <option selected :value="null" v-else>Pick a server</option>
      <option v-for="g,k in $store.state.guilds" :key="k" :value="k">{{g[0]}}</option>
    </select>
    <hr/>
    <div v-for="[k,v] in cmdIndex" :key="k">
      <img src="./assets/online.webp" class="emoji" v-if="v[1] && v[2] == null" />
      <img src="./assets/idle.webp" class="emoji" v-else-if="v[1] && v[2] != null" />
      <img src="./assets/dnd.webp" class="emoji" v-else />
      <span class="emoji-text">{{k}}<span v-if="v[2] != null"> - {{countdown(v[2])}}</span></span>
    </div>
  </div>
  <transition name="fade" mode="out-in">
    <div class="block" v-if="accessDenied || callbackWindow || ($store.state.loginUrl != null)">
      <div class="alert alert-info legend m-4" role="alert">
        <div v-if="accessDenied">
          <h4 class="alert-heading">You don't have access to this website</h4>
          {{ accessDenied === "nomutual" ? "You don't share a mutual server with me" : "You don't have the required roles" }}
        </div>
        <span v-else-if="callbackWindow && error == null">This window should close on it's own when it's done</span>
        <span v-else-if="callbackWindow && error != null">Error! {{error}}</span>
        <div v-else>
          <h4 class="alert-heading">You must log in to continue</h4>
          <button type="button" class="btn btn-primary float-right mt-2" @click="openLoginNoPrompt">Click here to login</button>
        </div>
      </div>
    </div>
  </transition>
  <WSStatus />
  <Toasts />
</template>

<script>
import Toasts from './components/Toasts.vue';
import WSStatus from './components/WSStatus.vue';
import types from './store/types';

function cooldownName(gid, cmd, role) {
  return (gid != null ? gid : '*')+"::economycustom::cc-"+cmd+(role != null ? ':'+role : '')
}
export default {
  components: { WSStatus, Toasts },
  name: 'App',
  data: () => ({
    selectedGuild: null,
    callbackWindow: false,
    error: null,
    countdownTime: new Date(),
    countdownInterval: -1
  }),
  computed: {
    flatRoleIDs() {
      if(this.selectedGuild == null || this.$store.state.roles[this.selectedGuild] == null) return [];
      return this.$store.state.roles[this.selectedGuild]
    },
    cmdIndex() {
      var output = {};
      var roles = this.flatRoleIDs
      var cmds = []
      for(let cmd in this.$store.state.commands) {
        cmd = cmd.split(":")[0]
        if(!cmds.includes(cmd)) cmds.push(cmd)
      }
      for(let cmd of cmds) {
        if(this.$store.state.commands[cmd] != null) 
          output[cmd] = [this.$store.state.commands[cmd] != null, this.$store.state.commands[cmd] == 1, this.$store.state.cooldowns[cooldownName(this.selectedGuild, cmd, null)]]
        for(var role of roles) {
          if(this.$store.state.commands[cmd+":"+role.id] != null) output[cmd] = [true, this.$store.state.commands[cmd+":"+role.id] == 1, this.$store.state.cooldowns[cooldownName(this.selectedGuild, cmd, role.id)]]
        }
      }
      return Object.entries(output).sort((a,b)=>{
        var as = a[1][0] ? (a[1][1] ? (a[1][2] != null ? 1 : 0) : (a[1][2] != null ? 2 : 3)) : 4
        var bs = b[1][0] ? (b[1][1] ? (b[1][2] != null ? 1 : 0) : (b[1][2] != null ? 2 : 3)) : 4
        if(as == bs){
          if(a[1][2] == null) return a[0].localeCompare(b[0]);
          else return a[1][2] - b[1][2];
        }
        return as - bs;
      })
    },
    ready() {
      return this.$store.state.wsState?.id === types.WS_STATES.READY.id
    },
    noData() {
      return Object.keys(this.$store.state.guilds) < 1;
    },
    accessDenied() {
      return this.$store.state.wsState?.id === types.WS_STATES.ACCESS_DENIED.id
    }
  },
  methods: {
    openLogin() {
      window.open(this.$store.state.loginUrl+"&prompt=consent", 'bewebtestlogin', 'popup') // dodgy, but should never break... hopefully
    },
    openLoginNoPrompt() {
      window.open(this.$store.state.loginUrl+"&prompt=none", 'bewebtestlogin', 'popup') // dodgy, but should never break... hopefully
    },
    countdown(v) {
      var diff = v - this.countdownTime
      var remaining = diff / 1000;
      if(remaining > 0){
        var seconds = Math.floor(remaining % 60);
        var minutes = Math.floor(remaining / 60) % 60;
        var hours = Math.floor(Math.floor(remaining / 60) / 60) % 24;
        var days = Math.floor(Math.floor(Math.floor(remaining / 60) / 60) / 24);
        return (days != 0 ? (days != 1 ? days+" days, " : days+" day, ") : "")+
        (hours != 0 ? (hours != 1 ? hours+" hours, " : hours+" hour, ") : "")+
        (minutes != 0 ? (minutes != 1 ? minutes+" minutes and " : minutes+" minute and ") : "")+
        (seconds != 1 ? seconds+" seconds" : seconds+" second");
      }
      return Math.floor(remaining)+" seconds"
    }
  },
  mounted() {
    if(window.location.pathname == "/callback") {
      this.callbackWindow = true;
      const search = new URLSearchParams(window.location.search);
      if(search.has("error")) {
        if(search.get("error") == "access_denied")
          this.error = "Access Denied!"
        else
          this.error = "Unknown error from Discord's side"
      } else {
        console.log("Hold on...")
        try {
          window.opener.loginCallback(search.get("code"))
          window.close()
        } catch (e) {
          this.error = "Something went wrong..."
          console.error(e)
        }
      }
    } else {
      console.log("Welcome to the BeanEconomyWebDemo!")
      this.countdownInterval = setInterval(() => {
        this.countdownTime = new Date()
        var copy = {...this.$store.state.cooldowns}
        for(var c in this.$store.state.cooldowns) {
          if(this.$store.state.cooldowns[c] < this.countdownTime)
            delete copy[c]
        }
        if(Object.keys(this.$store.state.cooldowns).length != Object.keys(copy).length){
          this.$store.commit(types.UPDATE_DATA, {cooldowns:copy})
        }
      }, 1000)
      // this.addToLog("Pick a server to use!")
      // this.addToLog("1. Prod @ws.fumple.pl/apiproxy", ()=>this.pickServer(true))
      // this.addToLog("2. Dev @ws.fumple.pl/apiproxydev", ()=>this.pickServer(false))
      // this.pickServer(window.location.pathname != "/devapi")
    }
  },
  beforeUnmount() {
    clearInterval(this.countdownInterval)
  }
}
</script>

<style scoped>
.emoji {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}
.emoji-text {
  position: relative;
  top: 1.5px;
  left: 2px;
}
.legend {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity ease-in-out 0.2s;
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.block {
  position: absolute;
  top: 39px;
  left: 0;
  bottom: 0;
  width: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(0,0,0,0.5);
  /*transition: background-color ease-in-out 1s;*/
}
</style>
