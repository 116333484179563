import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import ws from './store/ws'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "./style.css"

var app = createApp(App).use(store)
if(window.location.pathname != "/callback") {
    app = app.use(ws, {isProd:window.location.pathname != "/devapi", store})
}
app.mount('#app')
